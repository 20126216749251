import axios from 'axios';

import router from '@/router';
import store from '@/store';

import type { ServerResponse } from '@/types/ServerResponse';
import type { AxiosError } from 'axios';
import type {
    AxiosCacheInstance,
    CacheAxiosResponse,
    InternalCacheRequestConfig
} from 'axios-cache-interceptor';

enum StatusCode {
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InternalServerError = 500
}

export type InterceptedAxiosError = AxiosError & {
    isIntercepted: boolean;
};

export function markErrorIntercepted(error: AxiosError) {
    (error as InterceptedAxiosError).isIntercepted = true;
}

function markAppLoading(
    config?: InternalCacheRequestConfig | null,
    isLoading = true
) {
    // setting "silent" header will not show the loading indicator
    if (!config?.headers.silent) {
        store.dispatch(isLoading ? 'loading/show' : 'loading/hide');
    }
}

function forceLogout() {
    store.dispatch('user/forceLogout');

    router.push('/login');
}

function replaceView(path: string) {
    const { href } = router.resolve(router.currentRoute.fullPath);

    router.replace(path).finally(() => {
        history.replaceState({}, 'Not Found', href);
    });
}

function interceptError(error: AxiosError) {
    if (axios.isCancel(error)) {
        markErrorIntercepted(error);
    }

    const { response } = error;

    if (response) {
        const { status } = response;

        switch (status) {
            case StatusCode.InternalServerError: {
                store.dispatch(
                    'notification/error',
                    'Something went wrong. Please try again or contact support.'
                );

                markErrorIntercepted(error);

                break;
            }
            case StatusCode.Forbidden: {
                store.dispatch(
                    'notification/error',
                    'Please log in to continue'
                );

                markErrorIntercepted(error);

                forceLogout();

                break;
            }
            case StatusCode.NotFound: {
                markErrorIntercepted(error);

                replaceView('/404');

                break;
            }
            case StatusCode.Unauthorized: {
                store.dispatch(
                    'notification/error',
                    'Unauthorized: Access denied'
                );

                markErrorIntercepted(error);

                forceLogout();

                break;
            }
            default: {
                // removes AxiosError label from error messages
                error.name = '';

                return Promise.reject(error);
            }
        }
    }

    return Promise.reject(error);
}

function handleAborting(
    axiosCached: AxiosCacheInstance,
    config: InternalCacheRequestConfig
) {
    const { signal } = config;

    if (signal && typeof signal.addEventListener === 'function') {
        // handle request aborting
        signal.addEventListener('abort', () => {
            // once aborted remove the response from cache
            if (config.id) {
                axiosCached.storage.remove(config.id);
            }

            markAppLoading(null, false);
        });
    }
}

export function onRequestFulfilled(axiosCached: AxiosCacheInstance) {
    return function (config: InternalCacheRequestConfig) {
        markAppLoading(config);

        handleAborting(axiosCached, config);

        return config;
    };
}

export function onRequestRejected(response: ServerResponse) {
    markAppLoading(null, false);

    return response;
}

export function onResponseFulfilled(config: CacheAxiosResponse) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    markAppLoading(null, false);

    return config;
}

export function onResponseRejected(error: AxiosError) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    markAppLoading(null, false);

    return interceptError(error);
}
