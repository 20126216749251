<template>
    <a-banner v-if="!isKamManagedUser">
        <!-- for reference only <span
            v-if="
                isBetween(['2024-07-03 11:00'], ['2024-07-07 11:00']) &&
                !isNewSimplerTrafficBuyer
            "
        >
            Discover Our New Traffic Playbook For 2024
            <a href="https://letsgolook.at/TrafficPlaybook2024" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP HERE
            </a>
        </span> -->

        <div v-if="isAsigoBuyerOnly" class="mb-4">
            We are excited to announce that Asigo has been upgraded to DropServe
            with new sales material, new software and new strategies to help you
            maximize your sales and profits.
            <br />
            Review our new training to discover the new platform and take part
            in our latest challenge and to grow your hands-off agency.
            <br />
            Join the sessions
            <a href="/training/dropserve/index" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                here
            </a>
        </div>

        <div v-if="isDropServeBuyer" class="my-4">
            Welcome to the DropServe, your DropService whitelabel platform where
            we will guide you on starting and scaling your own DropService
            business. Start here:
            <a href="https://www.dropserve.ai/thankyou" target="_blank">
                https://www.dropserve.ai/thankyou
            </a>
        </div>

        <div v-if="isReseller && !isDropServeBuyer" class="my-4">
            Your Amp Sales Funnel has been updated to a simpler, more refined
            version that will help you to improve conversions.
            <br />
            To set this up and start moving leads forward,
            <a
                href="https://docs.google.com/document/d/1wslOSG9LE4mG3TfSx0TLGokuJmsnZhY0m5heSFBhHYQ/edit"
                target="_blank"
            >
                <v-icon color="primary" x-small>arrow-right</v-icon>
                please follow the steps listed here
            </a>
        </div>

        <!-- Banners For All Users -->
        <div
            v-if="isBetween(['2025-01-09 11:00'], ['2025-01-17 11:00'])"
            class="my-4"
        >
            Would You LIke Early Access To The Next Evolution Of AmpiFire? More
            Content, More Distribution, More Traffic, More Opportunities… In One
            Platform
            <a href="http://letsgolook.at/AmpKickstarter" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Demo & Access Details
            </a>
        </div>
        <div
            v-if="isBetween(['2025-02-03 11:00'], ['2025-02-11 11:00'])"
            class="my-4"
        >
            Discover How We “Power Up” Our 4-Part Formula For Getting Google
            Search & A.I. Answer Traffic With Rare Links From Over 10,000+ DA
            40+ Niche Sites
            <a href="https://letsgolook.at/blowlinks" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Free Training
            </a>
        </div>
        <div
            v-if="isBetween(['2025-02-12 11:00'], ['2025-02-13 11:00'])"
            class="my-4"
        >
            [LAST CHANCE] Final 24 Hours To Get Rare Guaranteed Links From DA
            40+ Sites For Your Top Pages & Clients With AmpiFire’s New Blog Link
            Outreach Wire
            <a href="https://letsgolook.at/AmpiFireBLOW" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your B.L.O.W. Packs Here
            </a>
        </div>

        <div v-if="showPressCableUserWelcomeMessage" class="my-4">
            You have PressCable credits remaining. Upgrade to Amp Credits
            <router-link to="/credits/convert">
                Here
                <v-icon color="primary" x-small>arrow-right</v-icon>
            </router-link>
        </div>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    },
    computed: {
        ...mapGetters('user', ['isReseller']),
        ...mapState('user', [
            'isLociCycleBuyer',
            'isAsigoBuyer',
            'isDropServeBuyer',
            'isBoringMethodBuyer',
            'isNewSimplerTrafficBuyer',
            'isPressCableUser',
            'hasPressCableCredits',
            'isKamManagedUser'
        ])
    }
})
export default class WelcomeBanner extends Vue {
    isReseller!: boolean;
    isLociCycleBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;
    isBoringMethodBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;
    isPressCableUser!: boolean;
    hasPressCableCredits!: boolean;
    isKamManagedUser!: boolean;

    get timestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    get showPressCableUserWelcomeMessage() {
        return this.isPressCableUser && this.hasPressCableCredits;
    }

    get isValidAsigoBuyer() {
        return this.isAsigoBuyer || this.isDropServeBuyer;
    }

    get isAsigoBuyerOnly() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    isBetween(
        [fromDate, fromZone = 'America/New_York']: string[],
        [toDate, toZone = 'America/New_York']: string[]
    ) {
        const now = this.$date();

        return (
            now.isAfter(this.$date(fromDate).tz(fromZone, true)) &&
            now.isBefore(this.$date(toDate).tz(toZone, true))
        );
    }
}
</script>
